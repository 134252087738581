.heading2Xl {
	font-size: min(14vw, 80px);
	line-height: 1.4;
	font-weight: 500;
	letter-spacing: -0.05rem;
	margin: 1rem 0;
	color: black;
}

.tagWrapper {
	color: #ffffff00;
	font-size: 2.2rem;
	line-height: 1.5;
	width: 100%;
	text-align: center;

	animation: 1s cubic-bezier(0.83, 0, 0.17, 1) 0.75s alphaBlackFadeIn;
	animation-fill-mode: forwards;
}

.icon {
	background-color: #222;
	border-radius: 999999px;
	padding: 5px;
}

.coverUp {
	position: relative;
	background-color: white;
	width: 90%;
	height: 100%;
	top: 0;
	left: 5%;

	height: 72.5vh;
	animation: 0.75s cubic-bezier(0.83, 0, 0.17, 1) 0.25s shrink;
	animation-fill-mode: forwards;
}

.punct {
	/* color: #fff;
	animation: 1.5s cubic-bezier(0.83, 0, 0.17, 1) 1.5s alphaGreyFadeIn;
	animation-fill-mode: forwards; */
	color: rgba(165, 165, 165, 1);
}

.contentWrapper {
	position: relative;
	width: 60%;
	left: 20%;
	border-style: solid;
	border-width: 4px;
	border-radius: 3px;
	backdrop-filter: blur(10px);
	margin-top: 40px;
	padding-bottom: 10px;
	height: 0px;

	background-color: rgba(255, 255, 255, 0.07);

	color: #fff;

	animation: 0.75s cubic-bezier(0.83, 0, 0.17, 1) 0.25s contentFadeIn;
	animation-fill-mode: forwards;
}

.ctaButton {
	margin-top: 20px;
	background-color: #222;
	height: 90px;
	border-radius: 20px;
	padding: 0px;
	line-height: 3em;
	color: white;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35), 0 10px 10px rgba(0, 0, 0, 0.3);
	opacity: 0;
	animation: 1s cubic-bezier(0.83, 0, 0.17, 1) 0.75s buttonFadeIn;
	animation-fill-mode: forwards;
	text-decoration: none !important;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ctaButton:hover {
	text-decoration: none !important;
	box-shadow: 0 21px 42px rgba(0, 0, 0, 0.5), 0 15px 15px rgba(0, 0, 0, 0.44);
}

/* LAYOUT */

.container {
	padding: 0 1rem;
	margin: 3rem auto 2rem;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.backToHome {
	margin: 3rem 0 0;
}

/* --------------- MEDIA --------------- */

@media (max-width: 900px) {
	.contentWrapper {
		position: relative;
		width: 95%;
		left: 2.5%;
		margin: 0px;
		top: 20px;
		border-style: solid;
		border-width: 4px;

		border-radius: 3px;
		backdrop-filter: blur(10px);

		margin-bottom: 20px;
	}
}

/* ------------- ANIMATIONS ------------- */

@keyframes alphaBlackFadeIn {
	0% {
		color: rgba(0, 0, 0, 0);
	}
	100% {
		color: rgba(0, 0, 0, 1);
	}
}

@keyframes alphaGreyFadeIn {
	0% {
		color: rgba(0, 0, 0, 0);
	}
	100% {
		color: rgba(165, 165, 165, 1);
	}
}

@keyframes contentFadeIn {
	0% {
		border-color: #fff;
		color: #fff;
		height: 0px;
	}
	100% {
		border-color: #000;
		color: #000;
		height: 625px;
	}
}

@keyframes buttonFadeIn {
	0% {
		opacity: 0;
		border-radius: 20px;
		padding: 0px;
		color: black;
	}
	/* 10% {
		border-radius: 20px;
		padding: 0px;
	} */

	100% {
		opacity: 1;
		color: white;
		padding: 14px;
		border-radius: 12px;
	}
}

@keyframes shrink {
	0% {
		height: 72.5vh;
	}
	100% {
		height: 0vh;
	}
}
