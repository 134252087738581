@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@200;500&display=swap');

body {
	margin: 0;
	font-family: "Figtree";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
